import { Route, Routes } from 'react-router-dom';
import OwnerPanel from './OwnerPanel';
import Branch from './Branch';

export const OwnerPanelRoutes = () => (
  <Routes>
    <Route path='/*' element={<OwnerPanel/>} />
    <Route path='branches/:branchId' element={<Branch />} /> 
  </Routes>
)
