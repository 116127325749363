import PropTypes from 'prop-types';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import ListBulletIcon from '@heroicons/react/24/solid/ListBulletIcon';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { usePopover } from '../../hooks/use-popover';
import { AccountPopover } from './account-popover';
import { BranchesPopover } from './branches-popover';
import { useAuthStore } from '../../hooks/useAuthStore';
import { SeverityPill } from '../../components/severity-pill';

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();
  const branchesPopover = usePopover();
  const { business, isLoading } = useAuthStore();

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
          {!isLoading && business.stripeStatus !== 'paid' && business.stripeStatus !== 'new_business' && (
            <SeverityPill color={'error'}>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <SvgIcon fontSize="small">
                    <ExclamationTriangleIcon />
                  </SvgIcon>   
                  <p>
                    La suscripción de {business.name} ha expirado. Pasa a la sección de suscripción.
                  </p>           
                  <SvgIcon fontSize="small">
                    <ExclamationTriangleIcon />
                  </SvgIcon>
                </Stack>
              </SeverityPill>
          )}
          {!isLoading && business.stripeStatus !== 'paid' && business.stripeStatus === 'new_business' && (
            <SeverityPill color={'warning'}>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <p>
                    Activa la suscripción de {business.name} para empezar a procesar reseñas.
                  </p>           
                </Stack>
              </SeverityPill>
          )}
            <Tooltip title="Sucursales">
              <IconButton
                onClick={branchesPopover.handleOpen}
                ref={branchesPopover.anchorRef}                
              >
                <SvgIcon fontSize="small">
                  <ListBulletIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: 'pointer',
                height: 20,
                width: 20
              }}
              src="../../../public/assets/avatars/user-default.png"
            />
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
      <BranchesPopover
        anchorEl={branchesPopover.anchorRef.current}
        open={branchesPopover.open}
        onClose={branchesPopover.handleClose}
      />
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func
};
