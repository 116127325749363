import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getUsersByBranchApi(id) {
    const url = `${envUrl}/users/branch/${id}`;
    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getUsersByBusinessApi(id) {
    const url = `${envUrl}/users/business/${id}`;
    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function patchUsersByBranchApi(id, data) {
    const url = `${envUrl}/users/branch/${id}`;
    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.patch(url, data, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}
