import BoltIcon from '@heroicons/react/24/outline/BoltIcon';
import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import TableCellsIcon from '@heroicons/react/24/solid/TableCellsIcon';
import DocumentTextIcon from '@heroicons/react/24/solid/DocumentTextIcon';
import TagIcon from '@heroicons/react/24/solid/TagIcon';
import Cog6ToothIcon from '@heroicons/react/24/solid/Cog6ToothIcon';
import CurrencyDollarIcon from '@heroicons/react/24/solid/CurrencyDollarIcon';
import CubeTransparentIcon from '@heroicons/react/24/solid/CubeTransparentIcon';
import { SvgIcon } from '@mui/material';

export const items = [
  {
    title: 'Resumen',
    path: '/dashboard',
    role: 'MANAGER',
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Tablas',
    path: '/tables',
    role: 'MANAGER',
    icon: (
      <SvgIcon fontSize="small">
        <TableCellsIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Grupos',
    path: '/groups',
    role: 'MANAGER',
    icon: (
      <SvgIcon fontSize="small">
        <TagIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Reportes',
    path: '/reports',
    role: 'MANAGER',
    icon: (
      <SvgIcon fontSize="small">
        <BoltIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Agregar Reseña',
    path: '/newReview',
    role: 'MANAGER',
    icon: (
      <SvgIcon fontSize="small">
        <DocumentTextIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Panel del Dueño',
    path: '/ownerPanel',
    role: 'OWNER',
    icon: (
      <SvgIcon fontSize="small">
        <Cog6ToothIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Suscripción',
    path: '/pricing',
    role: 'OWNER',
    icon: (
      <SvgIcon fontSize="small">
        <CurrencyDollarIcon />
      </SvgIcon>
    )
  },
  {
    title: 'Plan Actual',
    path: '/currentPlan',
    role: 'OWNER',
    icon: (
      <SvgIcon fontSize="small">
        <CubeTransparentIcon />
      </SvgIcon>
    )
  },
];
