import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { postReviewApi } from '../api/reviews';
import { ToastContainer, toast } from 'react-toastify';
import { ToastSuccess } from '../components/shared/Toast';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const NewReview = () => {
    const {branchId} = useParams();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
      initialValues: {
        review: '',
        submit: null
      },
      validationSchema: Yup.object({
        review: Yup
          .string()
          .max(255)
          .required('Inserta Reseña'),
      }),
      onSubmit: async (values, helpers) => {
        const id = toast.loading("Cargando...");
        setLoading(true);
        try {
          await postReviewApi(values.review, branchId);
          ToastSuccess(id, "Reseña Procesada");
          helpers.resetForm();
        } catch (err) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        } finally {
          setLoading(false);
        }
      },      
    });
  
    return (
      <>
        <ToastContainer />
        <Box
          sx={{
            backgroundColor: 'background.paper',
            flex: '1 1 auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              maxWidth: 550,
              px: 3,
              py: '100px',
              width: '100%'
            }}
          >
            <div>
              <Stack
                spacing={1}
                sx={{ mb: 3 }}
              >
                <Typography variant="h4">
                  Subir Reseña
                </Typography>
                <Typography variant="h8">
                  Coméntanos sobre tu experiencia, tu opinión es importante
                </Typography>
              </Stack>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.review && formik.errors.review)}
                    fullWidth
                    helperText={formik.touched.review && formik.errors.review}
                    label="Reseña"
                    name="review"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.review}
                    multiline
                    minRows={3}
                  />
                </Stack>
                {formik.errors.submit && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {formik.errors.submit}
                  </Typography>
                )}
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                  disabled={loading}
                >
                  Subir
                </Button>
              </form>
            </div>
          </Box>
        </Box>
      </>
    );
};
  
export default NewReview;