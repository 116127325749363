import { Box, Container, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { AllReviewsTable } from '../components/tables/all-reviews';
import { TotalReviews } from '../components/cards/total-reviews';
import { DatesFilter } from '../components/cards/dateFilter';
import { useEffect, useState } from 'react';
import { getReviewsApi } from '../api/reviews';
import { TopReviewsTable } from '../components/tables/top-reviews';
import { useAuthStore } from '../hooks/useAuthStore';

const Tables = () => {
  const [totalReviews, setTotalReviews] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  const {currentBranch} = useAuthStore();

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  }
  
  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(currentBranch.id, start, end);
        setTotalReviews(data.reviewsPage.totalReviews);
      } catch (error) {
          console.error('Error fetching account data:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && currentBranch.id){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, currentBranch])

  return(
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
        <Typography variant="h1">
            {currentBranch.name}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              lg={9}
            >
              <DatesFilter
              handleFilter={handleDateChange}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={3}
            >
              <TotalReviews
                sx={{ height: '100%' }}
                reviewsTotal={totalReviews}
              />
            </Grid>
            <Grid
              xs={12}
            >
              <TopReviewsTable
                type = 'positive'
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={12}
              >
              <TopReviewsTable
                type = 'negative'
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={12}
            >
              <AllReviewsTable
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  )
};

export default Tables;
