import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { SeverityPill } from '../../components/severity-pill';
import { useEffect, useState } from 'react';
import { dayOfWeekMapper } from '../../utils/dayOfWeekMapper';
import ReviewModal from '../reviewModal';
import { getReviewsApi } from '../../api/reviews';

const statusMap = {
  Neutral: 'warning',
  Positivo: 'success',
  Negativo: 'error'
};

export const TopReviewsTable = ({ sx, startDate, endDate, branchId, type = 'positive' }) => {
  const [modalParentReviewId, setModalParentReviewId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  
  const handleOpenModal = (rawReviewId) => {
    setModalParentReviewId(rawReviewId);
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);
    
  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(branchId, start, end, [], [], [type === 'positive' ? 'Positivo' : 'Negativo'], type === 'positive' ? 'scoreAsc' : 'scoreDesc');
        setReviews(data.reviewsPage.reviews.slice(0, 5));
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && branchId){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, branchId, type])
  
  return (
    <>
    <Card sx={sx}>
      <CardHeader 
        title={type === 'positive' ? 'Top Positivas' : 'Top Negativas'} 
        sx={{padding:'15px'}}
      />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">
                  Fecha
                </TableCell>
                <TableCell>
                  Sentimiento
                </TableCell>
                <TableCell>
                  Emoción
                </TableCell>
                <TableCell>
                  Resumen
                </TableCell>
                <TableCell>
                  Grupo
                </TableCell>
                <TableCell>
                  Puntaje
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              reviews.map((review) => {
                const date = format(new Date(review.createdAt), 'dd/MM/yyyy HH:mm');
                const dayOfTheWeek = format(new Date(review.createdAt), 'EEEE');
                return (
                  <TableRow
                    hover
                    key={review.id}
                  >
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {dayOfWeekMapper[dayOfTheWeek]}, {date}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      <SeverityPill color={statusMap[review.sentiment]}>
                        {review.sentiment}
                      </SeverityPill>
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                        {review.emotion}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {review.summary}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {review.group}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {review.score}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
    </Card>
    <ReviewModal 
      handleClose={handleCloseModal}
      open={openModal}
      parentReviewId={modalParentReviewId}
    />
    </>
  );
};

TopReviewsTable.prototype = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  branchId: PropTypes.number,
  type: PropTypes.string.isRequired,
  sx: PropTypes.object
};
