import { Box, Container, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { useEffect, useState } from 'react';
import { getReportsApi } from '../api/reports';
import { AIReport } from '../components/cards/ai-report';
import { useAuthStore } from '../hooks/useAuthStore';

const Reports = () => {
  const [reports, setReports] = useState([]);
  const {currentBranch} = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getReportsApi(currentBranch.id);
        setReports(data);      
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    currentBranch.id && fetchData();
  }, [currentBranch])
  
  return(
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">
            {currentBranch.name}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
            >
              {
                reports.map(report => (
                  <AIReport
                    key={report.id}
                    inputReport={report}
                    latestReport={false}
                  />
                ))
              }                
              {
                !reports.length && 
                <>No hay un reporte generado hasta el momento.</>
              }
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  )
};

export default Reports;
