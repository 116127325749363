import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../hooks/useAuthStore';
import { getToken } from '../utils/token';

export const PrivateRoute = ({children}) => {    
  const { isAuthenticated, checkAuthToken, startLogout } = useAuthStore();
  const token = getToken();

  useEffect(() => {
    const authenticateUser = async () => {
      if (token && !isAuthenticated) {
        try {
          await checkAuthToken();
        } catch (error) {
          console.error('Failed to check auth token:', error);
          startLogout();
        }
      }
    };
    authenticateUser();
  }, [token, isAuthenticated, checkAuthToken, startLogout]);

  if (!isAuthenticated && !token) {
    return <Navigate to='/login' />;
  }

  return children;
};
