import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthStore } from '../hooks/useAuthStore';
import { useLocation, useNavigate } from 'react-router-dom';

export const RoleGuard = ({ children, allowedRoles = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthStore();

  useEffect(() => {
    if (!allowedRoles.includes(user.role) && user.id) {
      navigate('/');
    }

  }, [navigate, location.pathname, user.role, allowedRoles, user]);

  return children;
};

RoleGuard.propTypes = {
  children: PropTypes.node
};
