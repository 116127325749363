import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export function loginApi(email, password) {
    const url = `${envUrl}/auth/login`;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, {email, password}, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export function signUpApi(username, email, password, businessName, businessDescription, branchName, branchAddress, branchGroups) {
    const url = `${envUrl}/auth/signup`;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, {username, email, password, businessName, businessDescription, branchName, branchAddress, branchGroups}, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function userByTokenApi() {
    const url = `${envUrl}/auth/user`;
    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}
