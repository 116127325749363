import PropTypes from 'prop-types';
import CalendarIcon from '@heroicons/react/24/solid/CalendarIcon';
import { Avatar, Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';

export const DatesFilter = ({ sx , handleFilter }) => {
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
      handleFilter(startDate, endDate);
    }, [startDate, endDate, handleFilter])
  
  const handleStartDateChange = (date) => {
      setStartDate(date);
  }
  
  const handleEndDateChange = (date) => {
      setEndDate(date);
  }

  const handleLastMonth = () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  }
  
  const handleLastThreeMonths = () => {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    setStartDate(threeMonthsAgo);
    setEndDate(new Date());
  }
  
  const handleLastWeek = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(oneWeekAgo);
    setEndDate(new Date());
  }

  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant="overline"
            >
              Filtro por Fechas
            </Typography>
            <Typography variant="h4">

            <Stack spacing={2} direction="row"> {/* Adjust spacing here */}
              <DatePicker 
                label='Fecha Inicial' 
                onChange={handleStartDateChange}
                maxDate={endDate}
                defaultValue={startDate}
                value={startDate}
                />
                
              <DatePicker 
                label='Fecha Final' 
                onChange={handleEndDateChange}
                minDate={startDate} 
                maxDate={new Date()}
                defaultValue={endDate}
                value={endDate}
              />

              <Button variant="contained" size="small" onClick={()=>handleLastWeek()}>
                Última semana
              </Button>
              <Button variant="contained" size="small" onClick={()=>handleLastMonth()}>
                Último mes
              </Button>
              <Button variant="contained" size="small" onClick={()=>handleLastThreeMonths()}>
                Últimos 3 meses
              </Button>
                
            </Stack>
            </Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              height: 56,
              width: 56
            }}
          >
            <SvgIcon>
              <CalendarIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

DatesFilter.propTypes = {
  value: PropTypes.string,
  sx: PropTypes.object
};
