
import { useDispatch, useSelector } from 'react-redux';
import { onAddBranches, onAddBusiness, onChangeCurrentBranch, onChecking, onLogin, onLogout } from '../store/auth/authSlice';
import { getToken, removeToken, setToken } from '../utils/token';
import { getBranchesByBusinessApi, getUserBranchesApi } from '../api/branch';
import { loginApi, signUpApi, userByTokenApi } from '../api/auth';
import { getBusinessByUserApi } from '../api/businesses';

export const useAuthStore = () => {
    const { branches, business, currentBranch, isAuthenticated, isLoading, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const startSignUp = async({username, email, password, businessName, businessDescription, branchName, branchAddress, branchGroups}) => {
        dispatch(onChecking());
        
        try {
            const response = await signUpApi(username, email, password, businessName, businessDescription, branchName, branchAddress, branchGroups)
            const token = response.accessToken;
            setToken(token);
        } catch (error) {
            throw new Error('Invalid Credentials');
        }
        
        try {
            const user = await userByTokenApi()
            if (user.role === 'OWNER'){
                const business = await getBusinessByUserApi()
                const branches = await getBranchesByBusinessApi(business.id);
                dispatch(onAddBranches([...branches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...branches[0]}));
            } 
            if (user.role === 'MANAGER'){
                const branches = await getUserBranchesApi()
                const extractedBranches = branches.map( branch => branch.branch );
                const business = branches[0]?.branch.business;
                dispatch(onAddBranches([...extractedBranches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...extractedBranches[0]}));
            }
            if (user.role === 'ADMIN'){
                throw new Error('Error setting user');
            }
            dispatch(onLogin({...user}));
        } catch (error) {
            dispatch(onLogout());
            throw new Error('Error setting user');
        }
    }

    const startLogin = async({email, password}) => {

        dispatch(onChecking());

        try {
            const response = await loginApi(email, password)
            const token = response.accessToken;
            setToken(token);
        } catch (error) {
            throw new Error('Invalid Credentials');
        }
        
        try {
            const user = await userByTokenApi()
            if (user.role === 'OWNER'){
                const business = await getBusinessByUserApi()
                const branches = await getBranchesByBusinessApi(business.id);
                dispatch(onAddBranches([...branches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...branches[0]}));
            } 
            if (user.role === 'MANAGER'){
                const branches = await getUserBranchesApi()
                const extractedBranches = branches.map( branch => branch.branch );
                const business = branches[0]?.branch.business;
                dispatch(onAddBranches([...extractedBranches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...extractedBranches[0]}));
            } 
            dispatch(onLogin({...user}));
        } catch (error) {
            dispatch(onLogout());
            throw new Error('Error setting user');
        }
    }
    
    const startLogout = () => {
        removeToken();
        dispatch(onLogout());
    }
    
    const checkAuthToken = async() => {
        const token = getToken();
        if (!token) return dispatch(onLogout());

        try {
            const user = await userByTokenApi()
            if (user.role === 'OWNER'){
                const business = await getBusinessByUserApi()
                const branches = await getBranchesByBusinessApi(business.id);
                dispatch(onAddBranches([...branches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...branches[0]}));
            } 
            if (user.role === 'MANAGER'){
                const branches = await getUserBranchesApi()
                const extractedBranches = branches.map( branch => branch.branch );
                const business = branches[0]?.branch.business;
                dispatch(onAddBranches([...extractedBranches]));
                dispatch(onAddBusiness({...business}));
                dispatch(onChangeCurrentBranch({...extractedBranches[0]}));
            } 
            dispatch(onLogin({...user}));
        } catch (error) {
            dispatch(onLogout());
            throw new Error('Error setting user');
        }
    }

    const updateCurrentBranch = (branch) => {
        dispatch(onChangeCurrentBranch({...branch}));
    }
    
    const updateBranches = (branches) => {
        dispatch(onAddBranches([...branches]));
    }

    return {
        // props
        branches,
        business,
        currentBranch,
        isAuthenticated,
        isLoading,
        user,
        // methods
        checkAuthToken,
        startLogin,
        startLogout,
        startSignUp,
        updateCurrentBranch,
        updateBranches
    }
}