import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { NewBranchForm } from './forms/new-branch-form';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

export default function FormModal({ open, handleClose, formType, handleSuccess, businessId }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {formType === 'branch' && 
            <NewBranchForm 
              handleSuccess={handleSuccess}
              businessId={businessId} 
            />
          }
        </Box>
      </Modal>
    </div>
  );
}
