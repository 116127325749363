import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getBusinessByUserApi() {
    const url = `${envUrl}/businesses/user`;
    const token = await getToken();
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
    .then(response => {
        return response.data;
    })
    .catch(err => {
        throw new Error(err);
    })
}

