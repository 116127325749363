import axios from 'axios';
import { resetTimeToMidnight } from '../utils/dates';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getReportsApi(branchId) {
    const url = `${envUrl}/reports/branch/${branchId}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getLatestReportApi(branchId) {
    const url = `${envUrl}/reports/branch/latest/${branchId}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getReportByIdApi(reportId) {
    const url = `${envUrl}/reports/${reportId}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function postReportApi(branchId, start, end) {
    const url = `${envUrl}/reports/branch/${branchId}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    return axios.post(url, { 
          "startDate": resetTimeToMidnight(start), 
          "finishDate": resetTimeToMidnight(end) ,
    }, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err.response.data.message);
        })
}
