import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getReviewsApi(branchId, start, end, emotions = [], groups = [], sentiments = [], sort, limit, page) {
    const allowedSorts = ['dateAsc', 'dateDesc', 'scoreAsc', 'scoreDesc', undefined];

    if (!allowedSorts.includes(sort)) {
        throw new Error('Invalid sort parameter');
    }
    
    let startDate = new Date(start).toISOString();
    let endDate = new Date(end).toISOString();
    let url = `${envUrl}/reviews/branch/${branchId}/?start=${startDate}&end=${endDate}`;
    if (emotions.length) {url += `&emotions=${JSON.stringify(emotions)}`;}
    if (groups.length) {url += `&groups=${JSON.stringify(groups)}`;}
    if (sentiments.length) {url += `&sentiments=${JSON.stringify(sentiments)}`;}
    if (sort) {url += `&sort=${sort}`;}
    if (limit) {url += `&limit=${limit}`;}
    if (page) {url += `&page=${page}`;}

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getReviewsByTimeApi(branchId, start, end, groupingUnit, timeUnit, emotions = [], groups = [], sentiments = []) {
    const allowedGroupingUnits = ['emotion', 'group', 'sentiment'];
    const allowedTimeUnits = ['date', 'day', 'hour', 'month'];

    if (!allowedGroupingUnits.includes(groupingUnit)) {
        throw new Error('Invalid grouping unit parameter');
    }
    
    if (!allowedTimeUnits.includes(timeUnit)) {
        throw new Error('Invalid time unit parameter');
    }
    
    let startDate = new Date(start).toLocaleDateString();
    let endDate = new Date(end).toLocaleDateString();
    let url = `${envUrl}/reviews/branch/byTime/${branchId}/?start=${startDate}&end=${endDate}&groupingUnit=${groupingUnit}&timeUnit=${timeUnit}`;
    if (emotions.length) {url += `&emotions=${JSON.stringify(emotions)}`;}
    if (groups.length) {url += `&groups=${JSON.stringify(groups)}`;}
    if (sentiments.length) {url += `&sentiments=${JSON.stringify(sentiments)}`;}

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getRawReviewByIdApi(rawReviewId) {
    const url = `${envUrl}/reviews/rawReview/${rawReviewId}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function postReviewApi(review, branchId) {
    const url = `${envUrl}/reviews/branch/${branchId}`;

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return axios.post(url, { review }, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}
