import cookies from "js-cookie"
import jwtDecode from "jwt-decode";

const ACCESS_TOKEN = "accessToken";

export function setToken(token) {
    cookies.set(`${ACCESS_TOKEN }`, token)
}

export function getToken() {
    const token = cookies.get(ACCESS_TOKEN)
    if (!token || token === 'null') return null;
    return tokenExpire(token) ? null : token;
}

export function removeToken() {
    cookies.remove(ACCESS_TOKEN)
}

export function tokenExpire(token) {
    const seconds = 60;
    const metatoken = jwtDecode(token);
    const { exp } = metatoken;
    const now = (Date.now() + seconds) / 1000;
    return now > exp;
}
