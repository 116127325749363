import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Unstable_Grid2 as Grid,
  TablePagination
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { SeverityPill } from '../../components/severity-pill';
import { useEffect, useState } from 'react';
import { dayOfWeekMapper } from '../../utils/dayOfWeekMapper';
import { colors } from '../../utils/colors';
import { CustomSeverityPill } from '../../components/custom-severity-pill';
import ReviewModal from '../reviewModal';
import { getReviewsApi } from '../../api/reviews';

const statusMap = {
  Neutral: 'warning',
  Positivo: 'success',
  Negativo: 'error'
};

export const AllReviewsTable = ({ sx, startDate, endDate, branchId, bySentiment = true, byGroup = true, byEmotion = true, ordering = true, group }) => {
  const [modalParentReviewId, setModalParentReviewId] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [groups, setGroups] = useState([]);
  const [emotions, setEmotions] = useState([]);
  const [sentiments, setSentiments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [groupedBySentiment, setGroupedBySentiment] = useState({
    "Negativo": 0,
    "Positivo": 0,
    "Neutral": 0
  });

  const handleOpenModal = (rawReviewId) => {
    setModalParentReviewId(rawReviewId);
    setOpenModal(true);
  }
  const handleCloseModal = () => setOpenModal(false);

  const [filterOptions, setFilterOptions] = useState({
    byDateAsc: false,
    byDateDesc: false,
    byScoreAsc: false,
    byScoreDesc: false,
    selectedGroups: [],
    selectedEmotions: [],
    selectedSentiments: [],
  });
  
  // Fetch groups, sentiments and emotions
  // Since all of these are dynamic, we need to fetch them
  // every time the startDate, endDate or branchId changes
  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(branchId, start, end);
        setGroups(Object.keys(data.groupByGroup));
        setSentiments(Object.keys(data.groupBySentiment));
        setEmotions(Object.keys(data.groupByEmotion));
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && branchId){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, branchId]);

  // Fetched data changes everytime either the filters or the pagination changes  
  useEffect(() => {
    const { byDateAsc, byDateDesc, byScoreAsc, byScoreDesc, selectedGroups, selectedEmotions, selectedSentiments } = filterOptions;

    let sort;
    if (byDateAsc) sort = 'dateAsc';
    if (byDateDesc) sort = 'dateDesc';
    if (byScoreAsc) sort = 'scoreAsc';
    if (byScoreDesc) sort = 'scoreDesc';

    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(branchId, start, end, selectedEmotions, group ? [group] : selectedGroups, selectedSentiments, sort, rowsPerPage, page);
        setFilteredData(data.reviewsPage.reviews);
        setTotalFiltered(data.reviewsPage.totalReviews);
        setGroupedBySentiment({
          "Negativo": 0,
          "Positivo": 0,
          "Neutral": 0,
          ...data.groupBySentiment,
        });
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && branchId){
      fetchData(startDate, endDate, branchId);
    }

  }, [filterOptions, startDate, endDate, branchId, group, rowsPerPage, page]);

  const handleChangeCheckBox = (event) => {
    const { name, checked } = event.target;
    setFilterOptions(prevOptions => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeSelectCategory = (category, values) => {
    if (category === 'sentiment') {
      setFilterOptions(prevOptions => ({
        ...prevOptions,
        selectedSentiments: values,
      }));
    }
    if (category === 'group') {
      setFilterOptions(prevOptions => ({
        ...prevOptions,
        selectedGroups: values,
      }));
    }
    if (category === 'emotion') {
      setFilterOptions(prevOptions => ({
        ...prevOptions,
        selectedEmotions: values,
      }));
    }
  };

  return (
    <>
    <Card sx={sx}>
      <CardHeader 
        sx={{
          padding:'20px',
        }}
        title="Todas las reseñas"
        subheader="Puedes hacer filtros combinados con cada uno de los siguientes campos."
      />
      <Grid 
        container
        spacing={3}
        sx={{
          paddingX:'20px',
          paddingBottom:'20px',
        }}
      >
        { bySentiment &&
          <Grid
            xs={12}
            md={6}
          >
            <Typography variant="subtitle2">
              Por sentimiento
            </Typography>
            <Select
              name='sentiment'
              multiple
              fullWidth
              value={filterOptions.selectedSentiments}
              onChange={(e) => {
                handleChangeSelectCategory('sentiment', e.target.value);
              }}
            >
              {
                sentiments.map((sentiment, index) => (
                  <MenuItem
                    key={index}
                    value={sentiment}
                  >
                    {sentiment}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        }
        { byGroup &&
          <Grid
            xs={12}
            md={6}
          >
        
            <Typography variant="subtitle2">
              Por grupo
            </Typography>
            <Select
              name='group'
              multiple
              fullWidth
              value={filterOptions.selectedGroups}
              onChange={(e) => {
                handleChangeSelectCategory('group', e.target.value);
              }}
            >
              {
                groups.map((group, index) => (
                  <MenuItem
                    key={index}
                    value={group}
                  >
                    {group}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        }
        { byEmotion &&
          <Grid
            xs={12}
            md={6}
          >
            <Typography variant="subtitle2">
              Por emoción
            </Typography>
            <Select
              name='emotion'
              multiple
              fullWidth
              value={filterOptions.selectedEmotions}
              onChange={(e) => {
                handleChangeSelectCategory('emotion', e.target.value);
              }}
            >
              {
                emotions.map((emotion, index) => (
                  <MenuItem
                    key={index}
                    value={emotion}
                  >
                    {emotion}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        }
      </Grid>

        <FormControl sx={{ marginX: '15px', marginY: '0px' }} component="fieldset" variant="standard">

        { ordering &&
          <>
          <Typography variant="subtitle2">
            Ordenamiento
          </Typography>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox checked={filterOptions.byDateAsc} 
                onChange={handleChangeCheckBox} 
                name="byDateAsc" 
                />
              }
              label={
                <Typography variant="body2">
                  Fecha Descendente
                </Typography>    
              }  
            />
            <FormControlLabel
              control={
                <Checkbox checked={filterOptions.byDateDesc} 
                onChange={handleChangeCheckBox} 
                name="byDateDesc" 
                />
              }
              label={
                <Typography variant="body2">
                  Fecha Ascendente
                </Typography>    
              }  
            />
            <FormControlLabel
              control={
                <Checkbox checked={filterOptions.byScoreAsc} 
                onChange={handleChangeCheckBox} 
                name="byScoreAsc" 
                />
              }
              label={
                <Typography variant="body2">
                  Puntaje Ascendente
                </Typography>    
              }  
            />
            <FormControlLabel
              control={
                <Checkbox checked={filterOptions.byScoreDesc} 
                onChange={handleChangeCheckBox} 
                name="byScoreDesc" 
                />
              }
              label={
                <Typography variant="body2">
                  Puntaje Descendente
                </Typography>    
              }  
            />
          </FormGroup>
          </>
        }
      </FormControl>

      <Scrollbar sx={{ flexGrow: 1 }}>

        <Box sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            margin: '30px',       
          }}
        >
          <SeverityPill color='success'>
            <Typography variant="subtitle1">
              Positivas: {groupedBySentiment['Positivo']} ({
                `${(groupedBySentiment['Positivo']*100/totalFiltered).toFixed(2)}%`
              }) 
            </Typography>
          </SeverityPill>
          <SeverityPill color='warning'>
            <Typography variant="subtitle1">
              Neutrales: {groupedBySentiment['Neutral']} ({
                `${(groupedBySentiment['Neutral']*100/totalFiltered).toFixed(2)}%`
              }) 
            </Typography>
          </SeverityPill>
          <SeverityPill color='error'>
            <Typography variant="subtitle1">
              Negativas: {groupedBySentiment['Negativo']} ({
                `${(groupedBySentiment['Negativo']*100/totalFiltered).toFixed(2)}%`
              }) 
            </Typography>
          </SeverityPill>
        </Box>

        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell sortDirection="desc">
                  Fecha
                </TableCell>
                <TableCell>
                  Sentimiento
                </TableCell>
                <TableCell>
                  Emoción
                </TableCell>
                <TableCell>
                  Resumen
                </TableCell>
                <TableCell>
                  Grupo
                </TableCell>
                <TableCell>
                  Puntaje
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((review) => {
                const date = format(new Date(review.createdAt), 'dd/MM/yyyy HH:mm');
                const dayOfTheWeek = format(new Date(review.createdAt), 'EEEE');
                return (
                  <TableRow
                    hover
                    key={review.id}
                  >
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {dayOfWeekMapper[dayOfTheWeek]}, {date}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      <SeverityPill color={statusMap[review.sentiment]}>
                        {review.sentiment}
                      </SeverityPill>
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      <CustomSeverityPill color={colors[emotions.indexOf(review.emotion)%colors.length]}>
                        {review.emotion}
                      </CustomSeverityPill>
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {review.summary}
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      <CustomSeverityPill color={colors[groups.indexOf(review.group)%colors.length]}>
                        {review.group}
                      </CustomSeverityPill>
                    </TableCell>
                    <TableCell onClick={()=>handleOpenModal(review.rawReviewId)}>
                      {review.score}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={totalFiltered}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <Divider />
    </Card>
    <ReviewModal
      handleClose={handleCloseModal}
      open={openModal}
      parentReviewId={modalParentReviewId}
    />
    </>
  );
};

AllReviewsTable.prototype = {
  reviews: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  branchId: PropTypes.number,
  group: PropTypes.string,
  sx: PropTypes.object,
  bySentiment: PropTypes.bool,
  byGroup: PropTypes.bool,
  byEmotion: PropTypes.bool,
  ordering: PropTypes.bool,
};
