import { Box, Container, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { ReviewsWeek } from '../components/charts/reviews-week';
import { DatesFilter } from '../components/cards/dateFilter';
import { getReviewsApi } from '../api/reviews';
import { useEffect, useState } from 'react';
import { ByEmotionPie } from '../components/charts/pie-emotion';
import { GroupsCard } from '../components/cards/groups-card';
import { TableGraphToggle } from '../components/cards/table-graph-toggle';
import { AllReviewsTable } from '../components/tables/all-reviews';
import { useAuthStore } from '../hooks/useAuthStore';

const Groups = () => {
  const [reviewsRawResponse, setReviewsRawResponse] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [tableGraphToggle, setTableGraphToggle] = useState(false);

  const {currentBranch} = useAuthStore();

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  }
  
  const handleGroupChange = (group) => {
    setSelectedGroup(group);
  }
  
  const handleToggle = () => {
    setTableGraphToggle(!tableGraphToggle);
  }
  
  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = selectedGroup?
          await getReviewsApi(currentBranch.id, start, end, [], [selectedGroup])
          : await getReviewsApi(currentBranch.id, start, end);
        setReviewsRawResponse(data);
      } catch (error) {
          console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && currentBranch.id){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, currentBranch, selectedGroup])

  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(currentBranch.id, start, end);
        setGroups(Object.keys(data["groupByGroup"]));  
      } catch (error) {
          console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && currentBranch.id){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, currentBranch])


  return(
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">
            {currentBranch.name}
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              lg={9}
            >
              <DatesFilter
              handleFilter={handleDateChange}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              xs={3}
            >
              <TableGraphToggle 
                tableGraphToggle={tableGraphToggle}
                handleToggle={handleToggle}
              />
            </Grid>
            <Grid
              xs={12}
            >
              <GroupsCard
                handleSelectGroup={handleGroupChange}
                groups={groups}
                selectedGroup={selectedGroup}
              />
            </Grid>
            {
              tableGraphToggle
              ?
              <>
                <Grid
                  xs={12}
                  md={6}
                  lg={4}
                  >
                  <ByEmotionPie
                    groupedByEmotion={reviewsRawResponse["groupByEmotion"]}
                    total={Object.values(reviewsRawResponse["groupByEmotion"]).reduce((acc, val) => acc + val, 0)}
                    sx={{ height: '100%' }}
                    />
                </Grid>
                <Grid
                  xs={12}
                  lg={8}
                  >
                  <ReviewsWeek
                    startDate={startDate}
                    endDate={endDate}
                    branchId={currentBranch.id}
                    group={selectedGroup}
                    sx={{ height: '100%' }}
                    />
                </Grid>
              </>
              :
              <>
                <Grid
                  xs={12}
                >
                  <AllReviewsTable
                    byGroup = {false}
                    startDate={startDate}
                    endDate={endDate}
                    branchId={currentBranch.id}
                    group={selectedGroup}
                    sx={{ height: '100%' }}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  )
};

export default Groups;
