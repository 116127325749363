import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getStripeCheckoutSessionApi } from '../api/stripe';

const ConfirmationPage = () => {
  const {sessionId} = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await getStripeCheckoutSessionApi(sessionId);;
        console.log(data);
        setSessionData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (sessionId) {
      fetchSession();
    }
  }, [sessionId]);

  if (loading) {
    return;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!sessionData) {
    return <p>No session data found.</p>;
  }

  const paymentSuccessful = sessionData.checkoutSession.payment_status === 'paid';

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        flex: '1 1 auto',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          maxWidth: 550,
          px: 3,
          py: '100px',
          width: '100%'
        }}
      >
        <div>
          <Stack
            sx={{ 
              alignItems: 'center' 
            }}
          >
            <img
              src="/tdd_logo_negro.png"
              width={295}
              height={76}
              alt="Logo"
            />
          </Stack>
            {paymentSuccessful ? (
              <div>
                <h1>¡Gracias por tu compra!</h1>
                <p>Tu pago de $ {sessionData.checkoutSession.amount_total / 100} {sessionData.checkoutSession.currency.toUpperCase()} fue exitoso.</p>
                <p>Comienza a disfrutar los beneficios de <i>Reviews</i> mediante el botón debajo.</p>
                <Button onClick={() => navigate("/")} variant='contained'>
                  Ir a página de inicio
                </Button>
              </div>
            ) : (
              <div>
                <h1>Pago Fallido</h1>
                <p>Tu pago no ha sido procesado.</p>
                <Button onClick={() => navigate("/pricing")} variant='contained'>
                  Intentar de Nuevo
                </Button>
              </div>
            )}
        </div>
      </Box>
    </Box>
  );
};

export default ConfirmationPage;
