import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../hooks/useAuthStore';

export const PublicRoute = ({children}) => {
  const {isAuthenticated} = useAuthStore();

  return (!isAuthenticated) ?
    children
    : <Navigate to='/' />
}
