import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getUserBranchesApi() {
    const url = `${envUrl}/branches/user`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getBranchesByBusinessApi(id) {
    const url = `${envUrl}/branches/business/${id}`;

    const token = await getToken();

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function getBranchApi(id) {
    const url = `${envUrl}/branches/${id}`;
    const token = await getToken();
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.get(url, config)
    .then(response => {
        return response.data;
    })
    .catch(err => {
        throw new Error(err);
    })
}

export async function postBranchApi(data) {
    const url = `${envUrl}/branches`;
    const token = await getToken();
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.post(url, data, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}

export async function patchBranchApi(id, data) {
    const url = `${envUrl}/branches/${id}`;
    const token = await getToken();
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    return axios.patch(url, data, config)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            throw new Error(err);
        })
}
