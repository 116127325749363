import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import { Box, Container, Stack, Typography } from '@mui/material';
import { NewBranchForm } from '../../components/forms/new-branch-form';
import { useEffect, useState } from 'react';
import { getBranchApi } from '../../api/branch';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { BranchUserForm } from '../../components/forms/branch-user-form';
import { useAuthStore } from '../../hooks/useAuthStore';
import { RoleGuard } from '../../guards/role-guard';
import { LllmGroupsForm } from '../../components/forms/llm-groups-form';
import { useParams } from 'react-router-dom';

const Branch = () => {
  const [branch, setBranch] = useState({});
  const [triggerFetch, setTriggerFetch] = useState(false);
  const {branchId} = useParams();
  const {business} = useAuthStore();
  
  const handleSuccess = () => {
    ToastSuccessSync("Procedimiento Exitoso");
    setTriggerFetch(prevState => !prevState);
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBranchApi(branchId);
        setBranch(data);      
      } catch (error) {
        console.error('Error fetching branch:', error);
      }
    };
    fetchData();  
  }, [branchId, triggerFetch])

  return (
    <RoleGuard allowedRoles={['OWNER']}>
    <DashboardLayout>
      <ToastContainer />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">
                  {branch.name}
                </Typography>
              </Stack>
            </Stack>
            <NewBranchForm 
              newBranch={false}
              businessId={business.id}
              branch={branch}
              handleSuccess={handleSuccess} />
            <BranchUserForm branch={branch}
              handleSuccess={handleSuccess} />
            <LllmGroupsForm branch={branch}
              handleSuccess={handleSuccess} />
          </Stack>
        </Container>
      </Box>
    </DashboardLayout>
    </RoleGuard>
  );
};

export default Branch;