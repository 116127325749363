import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../components/chart';
import { useEffect, useMemo, useState } from 'react';
import { sentiments } from '../../utils/sentiments';
import { getReviewsByTimeApi } from '../../api/reviews';

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: true
      }
    },
    colors: sentiments.colors,
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    legend: {
      show: true
    },
    theme: {
      mode: theme.palette.mode
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      title: {
        text: "Número de Reseñas",
      },
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: [
        '0:00', '1:00', '2:00', '3:00', '4:00', '5:00',
        '6:00', '7:00', '8:00', '9:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
        '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
      ],
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      },
    },
    yaxis: {
      title: {
        text: "Horas",
        offsetX: 10,
      },
      labels: {
        formatter: (value) => (`${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary
        }
      },
    }
  };
};

export const ReviewsHour = ({ sx, startDate, endDate, branchId }) => {
  const chartOptions = useChartOptions();
  const [groupedBySentiment, setGroupedBySentiment] = useState({});

  const chartSeries = useMemo(() => {    
    const initial = sentiments.tags.map((sentiment) => {
      return {
        name: sentiment,
        data: []
      }
    })

    if (!Object.keys(groupedBySentiment).length) return initial;
    
    for(let i = 0 ; i < 24 ; i++){
      sentiments.tags.forEach(sentiment => {
        const objectToModify = initial.find(obj => obj.name === sentiment);
        const val = groupedBySentiment[i][sentiment] ? groupedBySentiment[i][sentiment] : 0;
        objectToModify.data.push(val)
      })
    }

    return initial;
  }, [groupedBySentiment])

  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsByTimeApi(branchId, start, end, "sentiment", "hour");
        setGroupedBySentiment(data);
      } catch (error) {
          console.error('Error fetching reviews:', error);
      }
    };
    if(startDate !== '' && endDate !== '' && branchId){
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, branchId])

  return (
    <Card sx={sx}>
      <CardHeader
        sx={{
          paddingBottom: 0,
          marginBottom: 0
        }}
        title="Reseñas por hora"
      />
      <CardContent>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type='bar'
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

ReviewsHour.protoTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  branchId: PropTypes.number,
  sx: PropTypes.object
};
