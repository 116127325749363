import { useEffect, useState } from 'react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchBranchApi, postBranchApi } from '../../api/branch';
import { getGroupsCollections } from '../../api/groupsCollections';

export const NewBranchForm = ({newBranch = true, businessId, branch, handleSuccess }) => {
  const [currentGroup, setCurrentGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [groupsCollections, setGroupsCollections] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      status: true,
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup
        .string()
        .max(255)
        .required('Inserta Nombre'),
      address: Yup
        .string()
        .max(255)
        .required('Inserta Dirección'),
      status: Yup
        .boolean(),
    }),
    onSubmit: async (values, helpers) => {
      if(groups.length < 3) return;
      const businessIdAsNumber = Number(businessId);
      const dataToPost = {
        ...values, 
        businessId: businessIdAsNumber,
        groups: JSON.stringify(groups) 
      };
      newBranch ? console.log('newBranch', dataToPost) : console.log('modifiedBranch', dataToPost);
      try {
        if (!dataToPost.adminId) delete dataToPost.adminId;
        newBranch ? await postBranchApi(dataToPost) : await patchBranchApi(branch.id, dataToPost);
        helpers.resetForm();
        handleSuccess();      
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },      
  });

  useEffect(() => {
    if(!newBranch){
      formik.setValues({
        name: branch.name ? branch.name : '',
        address: branch.address ? branch.address : '',
        status: branch.status ? branch.status : false,
        submit: null
      }); 
    }
    // eslint-disable-next-line
  }, [branch, newBranch])
  
  const addGroupFormik = useFormik({
    initialValues: {
      group: '',
      submit: null
    },
    validationSchema: Yup.object({
      group: Yup
        .string()
        .max(60)
        .required('Inserta Nombre'),
    }),
    onSubmit: async (values, helpers) => {
      addGroup(values.group);
      helpers.resetForm();
    },
  });  

  const addGroup = (group) => {
    if (!groups.includes(group)){
      setGroups(sortAlphabetically([...groups, group]));
    }
  }
  
  const deleteGroup = (groupToDelete) => {
    setGroups(sortAlphabetically(groups.filter(group => group !== groupToDelete)));
  }
  
  const handleGroupsCollectionsChange = async (e, values) => {
    const groupName = values.props.value;
    setCurrentGroup(groupName);
    const groups = groupsCollections.find(groupsCollection => groupsCollection.name === groupName).groups;
    const groupsAsList = [...JSON.parse(groups)];
    setGroups(sortAlphabetically(groupsAsList));
  };

  const sortAlphabetically = (unsortedGroups) => {
    unsortedGroups.sort((a, b) => {
      let nameA = a.toUpperCase();
      let nameB = b.toUpperCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });  
    return unsortedGroups;
  }

  const sortGroupsCollectionsList = (unsortedGroupsCollections) => {
    unsortedGroupsCollections.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) {
          return -1;
      }
      if (nameA > nameB) {
          return 1;
      }
      return 0;
    });  
    return unsortedGroupsCollections;
  }

  useEffect(() => {
    const fetchGroupsCollections = async () => {
      try {
        const data = await getGroupsCollections();
        setGroupsCollections(sortGroupsCollectionsList(data));      
      } catch (error) {
          console.error('Error fetching parentReview:', error);
      }
    };  
    fetchGroupsCollections();
  }, [branch])

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader='Consulta los datos de tu sucursal'
          title='Datos de sucursal'
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  disabled={!newBranch}
                  fullWidth
                  label="Nombre de la sucursal"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  disabled={!newBranch}
                  fullWidth
                  label="Dirección"
                  name="address"
                  error={!!(formik.touched.address && formik.errors.address)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  minRows={3}
                  multiline
                  value={formik.values.address}
                />
              </Grid>
              {
                !newBranch &&
                <Grid
                xs={12}
                md={6}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!newBranch}
                        checked={formik.values.status}
                        value={formik.values.status}
                        name='status'
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}    
                    />
                    }
                    label="Activa"
                  />
                </Grid>
              }
              {
                newBranch &&
                <Grid
                  xs={12}
                  md={6}
                >
                  <Select
                    fullWidth
                    value={currentGroup}
                    onChange={handleGroupsCollectionsChange}
                  >
                    {
                      groupsCollections.map((groupsCollection) => (
                        <MenuItem
                          key={groupsCollection.id}
                          value={groupsCollection.name}
                        >
                          {groupsCollection.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
              }
              {
                newBranch &&
                <Grid
                  xs={12}
                >
                  <Stack
                    useFlexGap 
                    flexWrap="wrap"
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    sx={{
                      padding: '15px',
                      width: '100%'
                    }}
                  >
                    {
                      groups.map((group, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <Button 
                            onClick={()=>deleteGroup(group)}
                            variant="contained" 
                            color='error'
                            size="small" 
                          >
                            {group}
                            <SvgIcon sx={{marginLeft : '8px'}}>
                              <XMarkIcon />
                            </SvgIcon>
                          </Button>
                        </Stack>
                      ))
                    }
                  </Stack>
                </Grid>
              }
              {
                newBranch &&
                <Grid
                  xs={6}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}                  
                  >
                    <TextField
                      fullWidth
                      label="Nombre del grupo"
                      name="group"
                      onBlur={addGroupFormik.handleBlur}
                      onChange={addGroupFormik.handleChange}
                      value={addGroupFormik.values.group}
                    />
                    <Button
                      variant='contained'
                      size='small'
                      onClick={addGroupFormik.handleSubmit}
                    >
                      Agregar Grupo
                    </Button>
                  </Stack>
                </Grid>
              }
              {
                newBranch &&
                (groups.length < 3) && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                      Al menos se necesitan 3 grupos
                  </Typography>
                )
              }
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          { newBranch &&
            <Button variant="contained"
              type="submit">
              Crear Sucursal
            </Button>
          }
        </CardActions>

      </Card>
    </form>
  );
};
