import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getUsersByBranchApi, getUsersByBusinessApi, patchUsersByBranchApi } from '../../api/users';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const BranchUserForm = ({branch, handleSuccess}) => {
  const [modified, setModified] = useState(false);
  const [businessUsers, setBusinessUsers] = useState([]);

  const formik = useFormik({
    initialValues: {
      users: [],
      submit: null
    },
    validationSchema: Yup.object({
      users: Yup
        .array()
    }),
    onSubmit: async (values, helpers) => {
      const dataToPost = {...values}
      try {
        await patchUsersByBranchApi(branch.id, dataToPost);
        helpers.resetForm();
        setModified(false);
        handleSuccess();      
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },      
  });

  const handleChange = () => {
    setModified(true);
  } 

  useEffect(() => {
    const fetchBusinessUsers = async () => {
      if (branch.id) {
        try {
          const data = await getUsersByBusinessApi(branch.businessId);
          setBusinessUsers(data);      
        } catch (error) {
          console.error('Error fetching business users:', error);
        }
      }
    };
    const fetchCurrentBranchUsers = async () => {
      if (branch.id) {
        try {
          const users = await getUsersByBranchApi(branch.id);
          formik.setValues({
            users: users.map(user => user.id),
            submit: null
          });   
        } catch (error) {
          console.error('Error fetching current branch users:', error);
        }
      }
    };
    fetchBusinessUsers();  
    fetchCurrentBranchUsers(); 
    // eslint-disable-next-line
  }, [branch])

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader
          subheader="Usuarios con Acceso a Sucursal"
          title="Permisos"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <InputLabel>Usuarios con Acceso</InputLabel>
                <Select
                  name="users"
                  multiple
                  fullWidth
                  value={formik.values.users}
                  onChange={(e) => {formik.handleChange(e);handleChange();}}
                  MenuProps={MenuProps}
                  error={!!(formik.touched.users && formik.errors.users)}
                >
                  {businessUsers.map((user) => (
                    <MenuItem
                      key={user.id}
                      value={user.id}
                    >
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {formik.errors.submit && (
              <Typography
                color="error"
                sx={{ mt: 3 }}
                variant="body2"
              >
                {formik.errors.submit}
              </Typography>
            )}

          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          { modified &&
            <Button variant="contained"
type="submit">
              Modificar Permisos
            </Button>
          }
        </CardActions>
      </Card>
    </form>
  );
};
