import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useAuthStore } from '../../hooks/useAuthStore';

export const BranchesPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const {branches, updateCurrentBranch} = useAuthStore();

  const handleChangeBranch = useCallback(
    (branch) => {
      onClose?.();
      updateCurrentBranch(branch);
    },
    // eslint-disable-next-line
    [onClose]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">
          Sucursales
        </Typography>
      </Box>
      <Divider />

      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        {
          branches.map((branch) => 
            (
              <MenuItem onClick={()=>handleChangeBranch(branch)} key={branch.id}>
                {branch.name}
              </MenuItem>
            )
          )
        }
      </MenuList>
    </Popover>
  );
};

BranchesPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
